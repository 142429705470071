<template>
    <v-layout row justify-center>
        <v-dialog 
            v-model="open"
            persistent
            max-width="400"
            content-class="date-picker-dialog"
        >            
            <v-card class="card_dialog_delete">
                <v-card-text class="pt-5">
                    <div class="flex flex-row justify-md-space-between align-baseline">
                        <span class="c-header">{{ label }}</span>
                        <v-btn
                            class="btn-white"
                            text
                            @click="reset"
                        >
                            Reset to Default
                        </v-btn>
                    </div>
                </v-card-text>                 
                <v-card-text class="flex justify-center pb-0 mb-4 ">
                    <span class="text-md-body-1 shifl-color">
                        {{ ( date == null || date == '' )? 'Pick a date from Calendar below' : date }}
                    </span>                    
                </v-card-text>
                
                <div class="flex justify-center" @mousemove="redesignHeader">
                    <!-- <v-date-picker
                        v-if="multiple"
                        v-model="date"
                        width="400"
                        first-day-of-week="1"
                        :min="nowDate" :max="getEndDate"
                        no-title
                        range
                        :reactive="reactive" 
                    >
                    </v-date-picker>
                    <v-date-picker
                        v-else-if="!multiple"
                        v-model="thedate"
                        width="420"
                        :min="nowDate" :max="getEndDate"
                        first-day-of-week="1"
                        no-title
                    > -->
                    <v-date-picker
                        v-if="multiple"
                        v-model="date"
                        width="400"
                        first-day-of-week="1"
                        :min="minDate"
                        no-title
                        range
                        :reactive="reactive" 
                    >
                    </v-date-picker>
                    <v-date-picker
                        v-else-if="!multiple"
                        v-model="thedate"
                        width="420"
                        :min="minDate"
                        first-day-of-week="1"
                        no-title
                    >
                    </v-date-picker>
                </div>

                <div class="flex pa-5 mw-54">                  
                    <v-btn
                        class="btn-blue w-50 mr-2"
                        text
                        @click="confirm"
                    >
                        Confirm
                    </v-btn>
                    <v-btn
                        class="btn-white w-50"
                        text
                        @click="close">
                        Cancel
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
import moment from 'moment';
import jQuery from 'jquery';

export default {
    name: "DatePickerDialog",
    props: ['open', 'multiple', 'label', 'defaultDate'],
    data: () => ({
        isEmptyDaste: false,
        isMobile: false,
        date: null,
        minDate: '',
        dateNow: new Date(), 
        nowDate: new Date().toISOString().slice(0,10),
        thedate:'',
        reactive: false
    }),
    mounted() {
        if(this.open) {
            this.redesignHeader(8)
        }
    },
    updated() {
        if(!this.multiple) {
            if(this.thedate !== "" || this.thedate !== undefined) {
                this.date = this.thedate
            }
        }

        this.redesignHeader()
    },
    watch: {
        defaultDate(nv) {
            
            this.thedate = nv
        }
    },
    computed:{
        getEndDate() {
            var endDate = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1, 10);
            return endDate.toISOString().slice(0,10)
        }
    },
    methods: {
        checkdate(d){
            alert(d)
        },
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$emit('close')
        },
        confirm() {
            if(this.multiple) {
                // Note: the returned data is in array
                this.$emit('confirm', this.date.sort((d1, d2) => (moment(d1).utc(false) - moment(d2).utc(false))))
            } else {
                this.$emit('confirm', this.date)
            }
        },
        reset() {
            this.date = null
            this.multiple = false
        },
        getDate() {
            if(this.date !== null || this.date.length > 0 || this.date !== "") {
                if(this.multiple) {
                    if(this.date.length === 1) {
                        this.minDate = this.date[0]
                    } else {
                        this.minDate = ''
                    }

                    if(this.date.length > 1) {
                        let dates = [...this.date]
                        dates.sort((d1, d2) => {
                            return moment(d1).utc(false) - moment(d2).utc(false)
                        })

                        let d1 = new Date(dates[0]).toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"}) 
                        let d2 = new Date(dates[1]).toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"}) 
                        // return moment(dates[0]).utc(false).format('MMM D, YYYY')+' - '+moment(dates[1]).utc(false).format('MMM D, YYYY') 
                        return d1+' - '+d2
                    }
                    else {
                        let d1 = new Date(this.date[0]).toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"})
                        // return moment(this.date[0]).utc(false).format('MMMM D, YYYY')
                        return d1
                    }
                } else {
                    return moment(this.date+"T00:00:00.000000Z").format('MMMM D, YYYY')
                }
            }
        },
        redesignHeader() {
            let el = jQuery('.v-date-picker-header').children()
            if(el[0] !== undefined) {
                el[0].classList.add('order-md-2')
                el[0].classList.add('gen-color')
                el[1].classList.add('text-left')
                el[1].classList.add('order-md-1')
                el[2].classList.add('order-md-3')
                el[2].classList.add('gen-color')

                let th = jQuery('.v-date-picker-table.v-date-picker-table--date.theme--light').children()
                th[0].childNodes[0].innerHTML = "<tr><th class='shifl-color bg-white fs-16'>Mon</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Tue</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Wed</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Thu</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Fri</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Sat</th>"+
                                                "<th class='shifl-color bg-white fs-16'>Sun</th></tr>"
            }
        }
    },
};
</script>
<style lang="scss">
.date-picker-dialog {
    .shifl-color {
        color: #819FB2 !important;
        font-family: 'Inter-Regular', sans-serif !important;
        letter-spacing: 0 !important;
    }
    .gen-color {
        color: #0171A1 !important;
    }
    button.v-btn.v-date-picker-table__current {
        border-radius: 5px !important;
        font-weight: 600;
        color: #0171A1 !important;
        border: none !important;
    }
    button.v-btn.v-btn--active {
        border-radius: 4px !important;
        font-weight: 400;
        background-color: #0171A1 !important;
        /* border: 1px solid #0171A1 !important; */
        color: white !important;
        font-size: 16px !important;
        letter-spacing: 0;

        &::before {
            opacity: .1;
        }
    }
    th.accent.theme--light {
        background-color: #0171A1 !important;
    }
    th.accent--text.theme--light {
        color: #0171A1 !important;
    }
    .w-50 {
        width: 100%;
    }
    .mw-54 {
        max-width: 54%;
    }
    .fs-16 {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    .bg-white {
        background-color: white;
    }
    td, button.v-btn.v-btn--text {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    td, button.v-btn.v-date-picker-table__current {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    button.theme--light.v-btn.v-btn--flat {
        color: #4a4a4a !important;
    }
    .c-header {
        color: #4A4A4A;
        font-size: 18px;
        font-weight: 600;
    }
}    
</style>
